import {
  DESKTOP_1200,
  MOBILE_460,
  TABLET_768,
  TABLET_800,
  TABLET_992,
} from "../../styles/globals/sizes";

import { COLORS } from "../../styles/globals/colors";
import { Link } from "gatsby";
import styled from "styled-components";

export const FooterContainer = styled.div`
  min-height: 280px;
  background-color: ${COLORS.darkGray};
  padding: 30px;
  display: flex;
  justify-content: center;

  @media (max-width: ${DESKTOP_1200}px) {
    padding: 9px 2% 0px 2%;
  }

  @media (max-width: 880px) {
    padding: 20px;
  }

  @media (max-width: 801px) {
    padding: 0px;
    min-height: 0px;
  }
`;

export const InternalWrapper = styled.div`
  width: 90%;
  box-sizing: border-box;
  margin: auto;
  position: absolute;
  max-width: 1500px;
  padding: 10px 15px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 0;
    flex: 1;
  }
  @media (max-width: ${TABLET_768}px) {
    max-width: 100%;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
    padding: 0;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1036px) {
    flex-direction: column;
  }
`;

export const LogosDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1036px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 10px 30px 10px;
  }

  @media (max-width: ${TABLET_800}px) {
    padding: 10px 15px 0px 15px;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 20px;

  @media (max-width: 1036px) {
    justify-content: flex-end;
    margin-left: 7px;
    margin-top: 0px;
  }
`;

export const SocialMediaIcon = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 11px;
  transition: 0.1s all;
  :hover,
  :focus {
    transition: 0.1s all;
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  @media (max-width: ${TABLET_800}px) {
    width: 31px;
    height: 31px;
  }

  @media (max-width: 380px) {
    margin-right: 7px;
  }
`;

export const Logo = styled.img`
  width: 135px;
  height: 65px;
  @media (max-width: ${MOBILE_460}px) {
    width: 100px;
  }
`;

export const OtherPages = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 37px;

  @media (max-width: ${TABLET_800}px) {
    justify-content: center;
  }
`;

export const ItemsDiv = styled.div`
  display: flex;
  justify-content: right;

  @media (max-width: 1036px) {
    padding: 1px;
    justify-content: space-between;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: ${MOBILE_460}px) {
    justify-content: center;
  }
`;

export const SectionDiv = styled.div`
  max-width: 250px;
  min-width: 150px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${DESKTOP_1200}px) {
    margin-right: 10px;
    min-width: 100px;
    max-width: 200px;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    align-items: flex-start;
    margin: 30px 0 0;
    margin-left: 10px;
  }
`;

export const Title = styled.div`
  margin-bottom: 15px;
  font: normal normal normal 22px/26px Roboto;
  color: ${COLORS.white};
  @media (max-width: ${MOBILE_460}px) {
    margin-bottom: 10px;
    font: normal normal normal 18px/26px Roboto;
  }
`;

export const Text = styled.div`
  font: 400 15px/20px "Roboto";
  color: ${COLORS.white};

  @media (max-width: 740px) {
    font-size: 14px;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 85%;
  }
`;

export const TextPhone = styled.div`
  font: 400 15px/20px "Roboto";
  color: ${COLORS.white};

  @media (max-width: 740px) {
    font-size: 14px;
  }
`;
export const ListItem = styled.li`
  font: normal normal normal 15px/28px Roboto;
  color: ${COLORS.white};
  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 14px/24px Roboto;
    width: 90%;
  }
`;

export const CustomLink = styled.a`
  color: ${COLORS.white};
  line-height: 2px;
`;

export const CustomLinkHome = styled(Link)`
  color: ${COLORS.white};
  line-height: 2px;
`;

export const ContactDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  a {
    display: flex;
  }
`;

export const ContactPhone = styled(ContactDiv)`
  display: ${(props) => (props.mobile ? "none" : "flex")};

  @media (max-width: ${MOBILE_460}px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
  }
`;

export const Disclaimer = styled.p`
  font: 400 15px/20px "Roboto";
  color: ${COLORS.white};
  margin: 20px auto 0;
  width: 100%;
  max-width: 1009px;

  @media (max-width: 740px) {
    font-size: 14px;
  }
    @media (max-width: ${MOBILE_460}px) {
    padding: 0 10px 10px;
  }
`;